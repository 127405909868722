const DOMAINS = ['stratus.lol', 'dodgestrat.us', 'scantripcloud.com'] as const;
const REGIONS = ['us-east-1', 'ca-central-1', 'eu-central-1', 'ap-northeast-1'] as const;

export type DOMAIN = (typeof DOMAINS)[number] | (string & {});
export type REGION = (typeof REGIONS)[number] | (string & {});
export type AUTH_METHODS = 'stratus-user' | 'anonymous' | 'sso-non-stratus-user' | 'sso-stratus-user' | undefined;

export interface AppState {
  tenant: {
    slug: string;
    region: string;
    name: string;
    id: string;
    plan: string;
    timezone: string;
  };
  urlId: string;
  workflowId: string;
  nodeId: string;
  formId: string;
  processId: string;
  arn: string;
  domain: DOMAIN;
  debug: boolean;
  formUrl: string;
  startUrl: string;
  returnUrl: string;
  returnToken: string;
  formSource: 'file' | 'process' | '';
  timeOffset: number;
}

export const INITIAL_NODE_CONFIG: AppState = {
  workflowId: '',
  nodeId: '',
  formId: '',
  processId: '',
  arn: '',
  tenant: {
    region: '',
    slug: '',
    name: '',
    id: '',
    plan: '',
    timezone: '',
  },
  formUrl: '',
  domain: '',
  debug: false,
  urlId: '',
  startUrl: '',
  returnUrl: '',
  returnToken: '',
  formSource: '',
  timeOffset: 0
};
